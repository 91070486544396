import React from 'react';
import './footer.css'; 
// import logo from '../../assets/images/eco-logo.png';

import { Container, Row,Col,ListGroup,ListGroupItem } from 'reactstrap';
import {Link} from "react-router-dom"

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className='footer'>
      <Container>
        <Row>
          <Col lg='4' className='mb-4' md='6'>
              <div className='logo'>

                <div>
                  <h1 className='text-white'>ASAP Services</h1>
                </div>
                
              </div>
              <p className='footer__text mt-4'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                   Odit corrupti iusto dolorum expedita aperiam itaque
                    error. Iure totam cupiditate accusantium!
                </p>

          </Col>
          <Col lg='3' md='3' className='mb-4'>
            <div className="footer__quick-links">
              <h4 className="quick__links-title">Top Categories</h4>
              <ListGroup className='mb-3'>
                <ListGroupItem className='ps-0 border-0 '>
               <Link to='#'>Painting</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0 '>
                  <Link to='#'>Electrical</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0 '>
                  <Link to='#'>Plumbing</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0 '>
                  <Link to='#'>Cleaning</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>
          <Col lg='2' md='3' className='mb-4'>
          <div className="footer__quick-links">
              <h4 className="quick__links-title">Useful Links</h4>
              <ListGroup className='mb-3'>
                <ListGroupItem className='ps-0 border-0'>
                  <Link to='/Service'>Service</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0'>
                  <Link to='/cart'>Cart</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0'>
                  <Link to='/login'>Login</Link>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0'>
                  <Link to='#'>Privacy Policy</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>
          <Col lg='3' md='4' >
          <div className="footer__quick-links">
              <h4 className="quick__links-title">Contact</h4>
              <ListGroup className='footer__contact'>
              <ListGroup className='mb-3'>
                <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                  <span><i class="ri-map-pin-line"></i></span>
                  <p>Karasuno High School Miyagi Prefecture, Japan</p>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                <span><i class="ri-phone-line"></i></span>
                  <p>+91 9740229921</p>
                </ListGroupItem>
                <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                <span><i class="ri-mail-line"></i></span>
                  <p>sauravbhai17@gmail.com</p>
                </ListGroupItem>
              </ListGroup>
              </ListGroup>
            </div>
          </Col>
          <Col lg='12'>
            <p className='footer__copyright'>
              Copyright {year} developed by Sauravbhai. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer